.ant-modal-header .ant-modal-title {
  color: #154398;
  font-weight: 600;
  font-size: 24px;
}
.ant-modal-header {
  position: relative;
  padding: 16px 0px;
  margin: 0px 24px;
}
.ant-modal-header::after {
  content: "";
  background-image: -ms-linear-gradient(0deg, #154398 0, #ee1d23 100%);
  display: block;
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: -3px;
  left: 0px;
  width: 80px;
  transition: 0.3s;
  background-image: linear-gradient(90deg, #154398 0%, #e4353a 100%);
}
.ant-modal-footer {
  position: relative;
  padding: 16px 0px;
  margin: 0px 24px;
}
