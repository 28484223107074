/* margin */
.m-0 {
    margin: 0px !important;
}

.m-1  {
    margin: 1px !important;
}

.m-2  {
    margin: 2px !important;
}

.m-3  {
    margin: 3px !important;
}

.m-4  {
    margin: 4px !important;
}

.m-5  {
    margin: 5px !important;
}

.m-6  {
    margin: 6px !important;
}

.m-7  {
    margin: 7px !important;
}

.m-8  {
    margin: 8px !important;
}

.m-9  {
    margin: 9px !important;
}

.m-10 {
    margin: 10px !important;
}

.m-11  {
    margin: 11px !important;
}

.m-12  {
    margin: 12px !important;
}

.m-13  {
    margin: 13px !important;
}

.m-14  {
    margin: 14px !important;
}

.m-15  {
    margin: 15px !important;
}

.m-16  {
    margin: 16px !important;
}

.m-17  {
    margin: 17px !important;
}

.m-18  {
    margin: 18px !important;
}

.m-19  {
    margin: 19px !important;
}

.m-20 {
    margin: 20px !important;
}

.m-21  {
    margin: 21px !important;
}

.m-22  {
    margin: 22px !important;
}

.m-23  {
    margin: 23px !important;
}

.m-24  {
    margin: 24px !important;
}

.m-25  {
    margin: 25px !important;
}

.m-26  {
    margin: 26px !important;
}

.m-27  {
    margin: 27px !important;
}

.m-28  {
    margin: 28px !important;
}

.m-29  {
    margin: 29px !important;
}

.m-30 {
    margin: 30px !important;
}

.m-31  {
    margin: 31px !important;
}

.m-32  {
    margin: 32px !important;
}

.m-33  {
    margin: 33px !important;
}

.m-34  {
    margin: 34px !important;
}

.m-35  {
    margin: 35px !important;
}

.m-36  {
    margin: 36px !important;
}

.m-37  {
    margin: 37px !important;
}

.m-38  {
    margin: 38px !important;
}

.m-39  {
    margin: 39px !important;
}

.m-40 {
    margin: 40px !important;
}

.m-41  {
    margin: 41px !important;
}

.m-42  {
    margin: 42px !important;
}

.m-43  {
    margin: 43px !important;
}

.m-44  {
    margin: 44px !important;
}

.m-45  {
    margin: 45px !important;
}

.m-46  {
    margin: 46px !important;
}

.m-47  {
    margin: 47px !important;
}

.m-48  {
    margin: 48px !important;
}

.m-49  {
    margin: 49px !important;
}

.m-50 {
    margin: 50px !important;
}

.m-51  {
    margin: 51px !important;
}

.m-52  {
    margin: 52px !important;
}

.m-53  {
    margin: 53px !important;
}

.m-54  {
    margin: 54px !important;
}

.m-55  {
    margin: 55px !important;
}

.m-56  {
    margin: 56px !important;
}

.m-57  {
    margin: 57px !important;
}

.m-58  {
    margin: 58px !important;
}

.m-59  {
    margin: 59px !important;
}

.m-60 {
    margin: 60px !important;
}

.m-61  {
    margin: 61px !important;
}

.m-62  {
    margin: 62px !important;
}

.m-63  {
    margin: 63px !important;
}

.m-64  {
    margin: 64px !important;
}

.m-65  {
    margin: 65px !important;
}

.m-66  {
    margin: 66px !important;
}

.m-67  {
    margin: 67px !important;
}

.m-68  {
    margin: 68px !important;
}

.m-69  {
    margin: 69px !important;
}

.m-70 {
    margin: 70px !important;
}

.m-71  {
    margin: 71px !important;
}

.m-72  {
    margin: 72px !important;
}

.m-73  {
    margin: 73px !important;
}

.m-74  {
    margin: 74px !important;
}

.m-75  {
    margin: 75px !important;
}

.m-76  {
    margin: 76px !important;
}

.m-77  {
    margin: 77px !important;
}

.m-78  {
    margin: 78px !important;
}

.m-79  {
    margin: 79px !important;
}
