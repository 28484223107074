.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
}
.ant-menu-submenu-popup .ant-menu-vertical {
  min-width: 270px;
}
.ant-menu-submenu-popup .ant-menu-submenu-title {
  padding-right: 10px;
}
.ant-menu-submenu-popup .ant-menu-submenu {
  border-bottom: 1px dashed #e1e1e1;
}
.ant-menu-vertical .ant-menu-item {
  border-bottom: 1px dashed #e1e1e1;
}
.ant-menu-submenu-popup .ant-menu-title-content {
  color: #777;
  font-weight: 600;
}
.ant-menu-submenu-popup .ant-menu-submenu:hover .ant-menu-title-content {
  margin-left: 20px;
  color: #000;
  font-size: 14px;
}
.ant-menu-submenu-popup .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-submenu-popup
  .ant-menu-submenu:hover
  .ant-menu-title-content::before {
  content: "\2022";
  color: #00c590;
  font-weight: 700;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 20px;
  line-height: 1;
}
.ant-menu-submenu-popup .ant-menu-submenu:hover {
  background: #f7f7f7;
  border-radius: 5px;
  border-bottom: 1px solid #fff;
}
.ant-menu-submenu-popup .ant-menu-item-selected {
  background: #f7f7f7 !important;
  border-radius: 5px;
  border-bottom: 1px solid #fff;
}

.ant-menu-submenu-popup .ant-menu-item-selected .ant-menu-title-content {
  margin-left: 20px;
  color: #000;
  font-size: 14px;
}
.ant-menu-submenu-popup
  .ant-menu-item-selected
  .ant-menu-title-content::before {
  color: #00c590;
  font-weight: 700;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 20px;
  line-height: 1;
  content: "\2022";
}
.ant-menu-light .ant-menu-item:hover {
  color: transparent;
}
.ant-menu-submenu:hover::after {
  border-bottom: unset !important;
}
.ant-menu-light .ant-menu-submenu-title:hover {
  color: transparent !important;
}

.ant-menu-submenu-popup .ant-menu-item:hover {
  background: #f7f7f7;
  border-radius: 5px;
  border-bottom: 1px solid #fff;
}
.ant-menu-submenu-popup .ant-menu-item:hover .ant-menu-title-content {
  margin-left: 20px;
  color: #000;
  font-size: 14px;
}
.ant-menu-submenu-popup .ant-menu-item:hover .ant-menu-title-content::before {
  content: "\2022";
  color: #00c590;
  font-weight: 700;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 20px;
  line-height: 1;
}
.ant-menu-submenu-popup .ant-menu-item-group-title {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}
.ant-drawer-body .ant-menu-submenu-title {
  min-width: 270px;
  font-size: 14px;
}

.ant-drawer-body .ant-menu-submenu-title {
  padding-right: 10px;
}
.ant-drawer-body .ant-menu-submenu:not(:last-child) {
  border-bottom: 1px dashed #e1e1e1;
}
.ant-drawer-body .ant-menu-item:not(:last-child) {
  border-bottom: 1px dashed #e1e1e1;
}
.ant-drawer-body .ant-menu-item {
  min-width: 270px;
}
.ant-drawer-body .ant-menu-title-content {
  color: #777;
  font-weight: 600;
}
// .ant-drawer-body .ant-menu-submenu:hover .ant-menu-title-content {
//   margin-left: 0px;
//   color: #000;
//   font-size: 14px;
// }

.ant-drawer-body .ant-menu-item-icon {
  width: 100%;
}
.ant-drawer-body .ant-menu-submenu-arrow {
  display: flex;
}
// .ant-drawer-body .ant-menu-submenu:hover .ant-menu-title-content::before {
//   content: "\2022";
//   color: #00c590;
//   font-weight: 700;
//   display: inline-block;
//   width: 1em;
//   margin-left: 0em;
//   font-size: 20px;
//   line-height: 1;
// }
.ant-drawer-body .ant-menu-submenu:hover {
  background: #f7f7f7;
  border-radius: 5px;
  border-bottom: 1px solid #fff;
}
.ant-drawer-body .ant-menu-item-selected {
  background: #f7f7f7 !important;
  border-radius: 5px;
  border-bottom: 1px solid #fff;
}

.ant-drawer-body .ant-menu-item-selected .ant-menu-title-content {
  margin-left: 0px;
  color: #000;
  font-size: 14px;
}

.ant-drawer-body .ant-menu-item-selected .ant-menu-title-content::before {
  color: #00c590;
  font-weight: 700;
  display: inline-block;
  width: 1em;
  margin-left: 0em;
  font-size: 20px;
  line-height: 1;
  content: "\2022";
}

.ant-drawer-body .ant-menu-item:hover {
  background: #f7f7f7;
  border-radius: 5px;
  border-bottom: 1px solid #fff;
}

.ant-drawer-body .ant-menu-item:hover .ant-menu-title-content {
  margin-left: 0px;
  color: #000;
  font-size: 14px;
}
.ant-drawer-body .ant-menu-item:hover .ant-menu-title-content::before {
  content: "\2022";
  color: #00c590;
  font-weight: 700;
  display: inline-block;
  width: 1em;
  font-size: 20px;
  line-height: 1;
  margin-left: 0em;
}
.ant-drawer-body .ant-menu-inline {
  border: unset;
}
.btn-w100{
  width: 100%;
  height: 56px !important;
  background: #154398;
  border-radius: 4px !important;
}

.md-bottom-text {
  width: 100%!important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  
}
.link-regis {
  font-weight: 600;
  cursor: pointer;
  color: red;
  margin-left: 4px;
}
.re-otp{
  font-weight: 600;
  color: #154398;
}
.link-login {
  font-weight: 600;
  color: red;
  cursor: pointer;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  background: #EDF6FC;
}
.ant-upload.ant-upload-drag {
  border: 1px dashed #2260BD;
}
.left-btn{
  display: flex;
  justify-content: flex-end;
  
}
.register-enterprice-btn{
  height: 56px!important;
  padding: 13px 36px;
  line-height: 19px;
}
.dropdown-search-post{
  top: 162px!important;
}

.share-face {
  :hover {
    span {
      text-decoration: underline;
    }
  }
  padding: 0 5px;
  span {
    font-size: 12px;
    color: #838383;
    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }
}